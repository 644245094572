import { DateTime } from '../DateTime'

export interface Location {
    readonly id: number
    readonly latitude: number
    readonly longitude: number
    readonly accuracy: number
    readonly determinedAt: DateTime
    readonly createdAt: DateTime
    readonly savedAt: DateTime
    readonly provider: LocationProvider
    readonly appMode: AppMode
    readonly type: LocationType
    readonly address: string | null
    readonly places: string[] | null
    readonly flags: LocationFlag[]
}

export enum LocationProvider {
    ios = 'ios',
    android = 'android',
}

export enum AppMode {
    foreground = 'foreground',
    background = 'background',
}

export enum LocationType {
    tracking = 'tracking',
    draft = 'draft',
    draftCanceled = 'draft_canceled',
    order = 'order',
    orderPhoto = 'order_photo',
    shiftStart = 'shift_start',
    shiftEnd = 'shift_end',
    taskStart = 'task_start',
    taskEnd = 'task_end',
}

export enum LocationFlag {
    realtime = 'realtime',
    synced = 'synced',
    insideBranch = 'inside_branch',
    outsideBranch = 'outside_branch',
    outsideBranchAllowed = 'outside_branch_allowed',
    outsideBranchNotAllowed = 'outside_branch_not_allowed',
    ongoingShiftEnded = 'ongoing_shift_ended',
    requested = 'requested',
    autoRequest = 'auto_request',
    manualRequest = 'manual_request',
    silentRequest = 'silent_request',
    branchRequest = 'branch_request',
    forbiddenPlace = 'forbidden_place',
    enterBranch = 'enter_branch',
    exitBranch = 'exit_branch',
    invalidShiftStart = 'invalid_shift_start',
    invalidShiftEnd = 'invalid_shift_end',
    outsideBranchCheck = 'outside_branch_check',
    returnBranchCheck = 'return_branch_check',
    outsideBranchNotification = 'outside_branch_notification',
    leftBranchWithDraft = 'left_branch_with_draft',
    leftBranchWithoutDraft = 'left_branch_without_draft',
    outsideBranchWithDraft = 'outside_branch_with_draft',
    mock = 'mock',
}

export enum LocationRequestType {
    none = 'none',
    manual = 'manual',
    auto = 'auto',
    silent = 'silent',
    branch = 'branch',
}

export const latLngLiteral: (location: Location) => google.maps.LatLngLiteral = (location): google.maps.LatLngLiteral => ({
    lat: location.latitude,
    lng: location.longitude,
})
