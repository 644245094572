import { DateTime } from '../DateTime'
import { Location } from './Location'
import { Status } from './Status'

export interface Draft {
    readonly id: number
    readonly status: Status
    readonly licensePlate: string
    readonly location: Location
    readonly state: DraftState
    readonly createdAt: DateTime
    readonly updatedAt: DateTime
}

export enum DraftState {
    active = 'active',
    done = 'done',
    canceled = 'canceled',
    discarded = 'discarded',
}
